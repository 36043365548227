import React from 'react';
import PropTypes from 'prop-types';

const UserContext = React.createContext();

export const UserProvider = ({ children, value }) => {
  const [user, setUser] = React.useState({
    id: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phoneNumber: undefined,
    title: undefined,
    isAuthorizedSigner: undefined,
    registrationStatus: undefined,
    roles: undefined,
    companyId: undefined,
    companyName: undefined,
    companyType: undefined,
    contactId: undefined,
  });

  return (
    <UserContext.Provider
      value={value || {
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};

UserProvider.defaultProps = {
  value: undefined,
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      title: PropTypes.string,
      isAuthorizedSigner: PropTypes.bool,
      registrationStatus: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
      companyId: PropTypes.string,
      companyName: PropTypes.string,
      companyType: PropTypes.string,
      contactId: PropTypes.string,
      relatedCompanies: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })),
    }),
    setUser: PropTypes.func,
  }),
};
